<script lang="ts">
	import { css } from 'styled-system/css';
	import { grid } from 'styled-system/patterns';
	import IconLink from './IconLink.svelte';
</script>

<section>
	<h1 class={css({ textAlign: 'left' })}>
		Hi👋<br />
		I'm Haruhito Aso.<br />
		Backend Engineer.
	</h1>
	<div class={grid({ gridTemplateColumns: 'auto auto 1fr', justifySelf: 'start', gap: '0.6rem' })}>
		<IconLink iconRef="/icons/github.svg#symbol" left="-30%" title="github" url="https://github.com/hmnkpl" />
		<IconLink iconRef="/icons/resume.svg#symbol" left="-50%" title="resume" url="/resume" />
		<IconLink iconRef="/icons/mail.svg#symbol" left="-100%" title="contact me" url="/contact" />
	</div>
</section>
