<script lang="ts">
	import { css } from 'styled-system/css';
	import { flex } from 'styled-system/patterns';

	export let msg: string;
	export let left: string;
	let isHovered: boolean;

	const mouseover = (_: FocusEvent) => {
		isHovered = true;
	};
	const mouseleave = (_: MouseEvent) => {
		isHovered = false;
	};
</script>

<div
	on:focus={mouseover}
	on:mouseover={mouseover}
	on:mouseleave={mouseleave}
	class={flex({
		w: '2.1rem',
		h: '2.1rem',
		alignItems: 'center',
		justifyContent: 'center',
		borderWidth: '1px',
		borderRadius: '4px',
		borderColor: {
			base: 'transparent',
			_hover: 'link',
		},
		cursor: 'pointer',
		position: 'relative',
	})}
>
	<slot />
	{#if isHovered}
		<span
			class={css({
				display: 'inline-block',
				position: 'absolute',
				paddingX: '4px',
				bottom: '-30px',
				w: 'fit-content',
				h: '26px',
				bg: 'iconBg',
				borderWidth: '1px',
				borderColor: 'iconBg',
				borderRadius: '4px',
				whiteSpace: 'nowrap',
				left: left,
			})}
		>
			{msg}
		</span>
	{/if}
</div>
