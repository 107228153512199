import type { PageLoad } from './$types';

export const prerender = true;

export const load: PageLoad = async () => {
	const modules = import.meta.glob(`../contents/top/*.{md,svx,svelte.md}`);

	const topPromises = Object.entries(modules).map(([_, resolver]) =>
		resolver().then((post) => {
			return mdToModel(post as unknown as App.MdsvexFile);
		})
	);

	const topCards = await Promise.all(topPromises);
	const filtlered = topCards.filter((it) => !it.draft).sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));
	return {
		cards: filtlered,
	};
};

const mdToModel = (md: App.MdsvexFile) =>
	({
		component: md.default,
		title: md.metadata.title,
		date: md.metadata.date,
		draft: md.metadata.draft === 'true',
	} as App.TopCardModel);
