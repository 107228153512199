<script lang="ts">
	import type { Cmp } from '$lib/features/shared/types/component';
	import { css } from 'styled-system/css';

	export let title: string;
	export let cmp: Cmp;
</script>

<div
	class={css({
		fontSize: '1.5rem',
		fontWeight: 'bold',
		borderBottom: '0.2rem solid',
		borderColor: 'line',
		marginBottom: '0.4rem',
	})}
>
	{title}
</div>
<div class={css({ fontSize: '1.2rem' })}>
	<svelte:component this={cmp} />
</div>
