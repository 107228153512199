<script lang="ts">
	import Tooltip from '$lib/features/shared/components/Tooltip.svelte';
	import { css } from 'styled-system/css';

	export let url: string;
	export let iconRef: string;
	export let title: string;
	export let left: string;
</script>

<a href={url} class={css({ color: { base: 'text', _hover: 'link' }, w: '2.1rem', h: '2.1rem' })}>
	<Tooltip msg={title} {left}>
		<svg class={css({ w: '1.6rem', h: '1.6rem' })}>
			<use xlink:href={iconRef} />
		</svg>
	</Tooltip>
</a>
